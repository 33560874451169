import React, { useState } from "react";
// import resumeFile from "../documents/resume.pdf";
import CountUp from "react-countup";
import { InView } from "react-intersection-observer";

const Resume = ({ classicHeader, darkTheme }) => {
  const [startAnimation, setStartAnimation] = useState(false);
  const [hasAnimated, setHasAnimated] = useState(false);
  const educationDetails = [
    {
      yearRange: "",
      title: "Bachelor's degree, Computer Science",
      place: "Texas State University",
      desc: "",
    },
  ];

  const experienceDetails = [
    {
      yearRange: "2020 - 2024",
      title: "Senior Software Developer",
      place: "Oracle",
      desc: `- Worked as a Software Engineer, specializing in building dynamic web applications.\n- Conceptualized and developed an internal recurring task scheduler and tracker, covering both user interface and system processes.\n
      - Pioneered a tax statement administrative application, managing both user-facing features and server-side functionalities.\n
      - Spearheaded backend optimizations which contributed to a 15% increase in user satisfaction and engagement.\n
      - Delivered a 20% boost in application scalability and performance through effective cloud resource management.\n
      - Oversaw high-availability databases, achieving a 25% reduction in downtime and data loss.\n
      - Played a pivotal role in the implementation of key regulatory compliance components.\n
      - Championed project quality, consistently suggesting and executing enhancements.\n
      - Innovated storage solutions, achieving cost savings while bolstering data accessibility and security.\n
      - Devised a universal search functionality for a client-oriented analytics tool, enabling instantaneous access to all data entities within one intuitive platform.`,
    },
    {
      yearRange: "2015 - 2020",
      title: "Senior Full Stack Developer",
      place: "Amazon Web Services (AWS)",
      desc: `- Built a responsive website from the ground up, incorporating intuitive content management capabilities that empower content creators with a user-friendly administrative interface.
      - Employed modern frameworks to ensure cross-platform compatibility, resulting in a standalone desktop application, while also harnessing powerful runtime environments for efficient data operations.
      - Pioneered the design and development of an application that enabled business expansion by facilitating back-office services globally.
      - Collaborated with a team to introduce new features to the company's website, ensuring seamless integration and functionality.
      - Innovated a CI/CD platform to automate crucial processes, streamlining the workflow and enhancing efficiency.
      - Deployed code analysis tools to gain insights into potential bugs, redundancies, and areas of improvement, fostering a culture of continuous code quality enhancement.
      - Instituted a version control mechanism that enabled tailored user experiences through conditional feature toggling.
      - Championed test-driven development, bolstering the software's reliability and longevity through comprehensive testing at multiple levels.`,
    },
    {
      yearRange: "2013 - 2015",
      title: "Software Developer",
      place: "Sidebench",
      desc: `- Introduced new features using contemporary development stacks and package management tools.
      - Led the complete spectrum of front-end processes, including UI implementation, application architecture, feature rollout, client coordination, and synergizing with back-end developers and design teams to achieve optimum results.
      - Employed cutting-edge design components to enhance application aesthetics and functionality.
      - Established a rigorous code review program and introduced a fresh development workflow. Conducted bi-weekly sessions with developers to scrutinize recent work and strategize upcoming projects.
      - Independently developed and upheld a web-based platform for a renowned nonprofit organization that managed reward qualifications and processing for extensive fundraising campaigns. Over time, the platform seamlessly scaled to support a vast number of coordinators and participants, orchestrating complex data exchanges with multiple vendors on a daily schedule.
      - Engineered applications capable of interfacing seamlessly with a diverse array of services and platforms, from social media sites to project management tools and proprietary in-house web services.`,
    },
    {
      yearRange: "2012 - 2013",
      title: "Software Developer",
      place: "Frogslayer",
      desc: `- Enhanced and maintained legacy codebases.
      - Acted as a database administrator, aiding in website data analysis.
      - Developed utilities to facilitate and visualize routine database operations.`,
    },
  ];

  const skills = [
    {
      name: "React.js",
      percent: 100,
    },
    {
      name: "PHP/Laravel/Symfony",
      percent: 90,
    },
    {
      name: "Angular",
      percent: 90,
    },
    {
      name: "React Native",
      percent: 95,
    },
    {
      name: "Vue.js",
      percent: 100,
    },
    {
      name: "MongoDB/PostgreSQL/MS SQL/MySQL",
      percent: 100,
    },
    {
      name: "Node.js",
      percent: 95,
    },
    {
      name: "Docker/Jenkins",
      percent: 100,
    },
    {
      name: "C#/.NET",
      percent: 100,
    },
    {
      name: "AWS/Azure/GCP",
      percent: 95,
    },
    
    {
      name: "Python/Django/Flask",
      percent: 95,
    },
    {
      name: "HTML5/CSS3/JavaScript/TypeScript",
      percent: 100,
    },
  ];

  return (
    <section
      id="resume"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme
                ? "text-muted-custom opacity-1"
                : "text-light opacity-4")
            }
          >
            Summary
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Resume
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gx-5">
          {/* My Education */}
          {/* My Experience */}
          <div>
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Experience
            </h2>
            {experienceDetails.length > 0 &&
              experienceDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  {value.desc.split("\n").map((descPoint, idx) => (
                    <p
                      key={idx}
                      className={"mb-0 " + (darkTheme ? "text-white-50" : "")}
                    >
                      {descPoint.trim()}
                    </p>
                  ))}
                </div>
              ))}
          </div>
          <div>
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Education
            </h2>
            {educationDetails.length > 0 &&
              educationDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  {/* <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p> */}
                  {/* Split the desc by line breaks and render each point */}
                  {value.desc.split(".").map((descPoint, idx) => (
                    <p
                      key={idx}
                      className={"mb-0 " + (darkTheme ? "text-white-50" : "")}
                    >
                      {descPoint.trim()}
                    </p>
                  ))}
                </div>
              ))}
          </div>
        </div>
        {/* My Skills */}
        <div>
          <h2
            className={
              "text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")
            }
          >
            My Skills
          </h2>

          <InView
            as="div"
            triggerOnce
            onChange={(inView) => {
              if (inView && !hasAnimated) {
                setStartAnimation(true);
                setHasAnimated(true);
              }
            }}
          >
            <div className="row gx-5">
              {skills.length > 0 &&
                skills.map((skill, index) => (
                  <div key={index} className="col-md-6">
                    <p
                      className={
                        "fw-500 text-start mb-2 " +
                        (darkTheme ? "text-light" : "text-dark")
                      }
                    >
                      {skill.name}{" "}
                      <span className="float-end">
                        {startAnimation ? (
                          <CountUp end={skill.percent} duration={1.5} />
                        ) : (
                          "0"
                        )}
                        %
                      </span>
                    </p>
                    <div
                      className={
                        "progress  progress-sm mb-4 " +
                        (darkTheme ? "bg-dark" : "")
                      }
                    >
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: startAnimation ? skill.percent + "%" : "0%",
                          transition: "all 1.5s",
                        }}
                        aria-valuenow={startAnimation ? skill.percent : 0}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      />
                    </div>
                  </div>
                ))}
            </div>
          </InView>
        </div>
        {/* <div className="text-center mt-5">
          <a
            className="btn btn-outline-secondary rounded-pill shadow-none"
            href={resumeFile}
            download
          >
            Download CV
            <span className="ms-1">
              <i className="fas fa-download" />
            </span>
          </a>
        </div> */}
      </div>
    </section>
  );
};

export default Resume;
